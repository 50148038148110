/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {

        // Filter
        jQuery('.js-filter').on('click', function () {
            var $filter = jQuery(this).attr('data-filter');
            jQuery('.js-filterable').addClass('is-hidden');
            jQuery('.js-filterable[data-elem=' + $filter + ']').removeClass('is-hidden');
        });

    }
}
