/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper from 'swiper/js/swiper.min';
import gsap from "gsap";
import CustomEase from "@lib/gsap-pro/CustomEase";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import CookieManager from '@components/cookie-manager';
import customGoogleMap from '@components/custom-google-map.js';
import MaterializeForm from '@components/materialize-form.js';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import annonces from '@pages/annonces.js';
import realisations from '@pages/realisations.js';
import investissements from '@pages/investissements.js';
import animations from '@pages/animations.js';
import sample from '@pages/sample.js';
import contact from '@pages/contact.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        'file': animations,
        'dependencies': [app, gsap, ScrollTrigger, Menu, Kira, CustomEase]
    },
    {
        'file': main,
        'dependencies': [app, CookieManager, Swiper, customGoogleMap]
    },
    {
        'file': annonces,
        'dependencies': [app, Swiper],
        'resolve': '.single-annonces'
    },
    {
        'file': realisations,
        'dependencies': [app, Swiper],
        'resolve': '.single-realisation'
    },
    {
        'file': investissements,
        'dependencies': [app, Swiper],
        'resolve': '#page-invest'
    },
    {
        'file': sample,
        'dependencies': [app],
        'resolve': '#page-sample'
    },
    {
        'file': contact,
        'dependencies': [app, MaterializeForm, customGoogleMap],
        'resolve': '#page-contact'
    }
]);

/*
|
| Run
|------
*/
(($) => {
    routes.load()
})(jQuery);

