/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Swiper, customGoogleMap) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $map = $('#map'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
        ;


        /*
		|
		| Cookie Manager
		|-----------------
		*/
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        $body.on('loaderEnd', () => console.log('ended'))

        /*
		|
		| swiper login
		|-----------------
		*/
        var swiperloading = new Swiper("#swiper-loading", {
            slidesPerView: 1,
            autoplay: {
                delay: 200,
            },
            speed: 100,
            effect: "fade",
            loop: true,
        });


        /*
		|
		| menu secondaire
		|-----------------
		*/
        $(document).on('click', '.btn-menu-secondary', function (e) {
            jQuery(".menu-secondary").slideToggle('slow');
        });


        jQuery('.js-filter').on('click', function () {
            var $filter = jQuery(this).attr('data-filter');

            jQuery(".js-filter").removeClass('active');
            jQuery(this).addClass('active');

            jQuery('.js-filterable').addClass('is-hidden');
            jQuery('.js-filterable[data-elem=' + $filter + ']').removeClass('is-hidden');

        });


        /*
		|
		| Mouse transform
		|-----------------
		*/
        window.addEventListener('DOMContentLoaded', () => {
            const mouse_circle = document.getElementById('mouse_circle');

            // const mousezone = document.querySelector('.mousezone');

            document.addEventListener('mousemove', (e) => {
                mouse_circle.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)';
            });

            jQuery(".mousezone").mouseover(function () {
                jQuery('.mouse_circle').addClass('moused');
            });

            jQuery(".mousezone").mouseout(function () {
                jQuery('.mouse_circle').removeClass('moused');
            });

            jQuery(".section-prestation").mouseover(function () {
                jQuery('.mouse_circle').addClass('mouse_glass');
            });

            jQuery(".section-prestation").mouseout(function () {
                jQuery('.mouse_circle').removeClass('mouse_glass');
            });

        });

        // Swiper
        var swiperannonce = new Swiper("#swiper-annonce", {
            spaceBetween: 0,
            slidesPerView: 3,
            centeredSlides: true,
            loop: true,
            navigation: {
                nextEl: ".swiper-button-anno-next",
                prevEl: ".swiper-button-anno-prev"
            }
        });


        /*
        |
        | Slider home
        |-----------------
        */
        var lastScrollTop = 0;
        jQuery(window).scroll(function () {
            var scroll = jQuery(window).scrollTop();
            var st = $(this).scrollTop();
            if (scroll >= 200) {
                if (st > lastScrollTop) {
                    jQuery('#header').removeClass("fixed");
                    jQuery('#header').addClass("fixedup");
                    jQuery('#header').removeClass("fixeddown");
                } else {
                    jQuery('#header').addClass("fixed");
                    jQuery('#header').removeClass("fixedup");
                    jQuery('#header').addClass("fixeddown");
                }
                lastScrollTop = st;
            } else {
                jQuery('#header').removeClass("fixed");
            }
        });

        /*
        |
        | Size title
        |-----------------
        */
        if (jQuery('.bannerhome').height() < 760) {
            jQuery('this').find('h1').addClass("titlesize");
        } else {
            jQuery('this').find('h1').addClass("titlesize");
        }

        jQuery(document).ready(function () {
            updatetitle();
            jQuery(window).resize(function () {
                updatetitle();
            });
        });

        function updatetitle() {
            if (jQuery('.bannerhome').height() < 760) {
                jQuery('.item-content').addClass("titlesize");
            } else {
                jQuery('.item-content').removeClass("titlesize");
            }
        }



        /*
        |
        | Map
        |-----------------
        */
        $.each($map, function () {
            var $map = $(this);

            var map = new customGoogleMap($map, {
                'markers': $map.find('.marker'),
                'zoom': 14
              });
        });



    }
}
