/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|--------------------------------------------------------------------------------
|                                  Custom Google Map
|--------------------------------------------------------------------------------
|
| Scroller is a lightweight library to manage "animated & looped" sliding contents
| It uses Greensock as main dependency
|
*/

/*
|
| Class
|--------
|
*/
class customGoogleMap {
    /*
    |
    | Constructor
    |--------------
    */
    constructor($map, params) {
        this.map = $map;
        this.params = params;
        this.init();
    }

    /*
    |
    | init
    |-------
    */
    init() {
        var _this = this;
        var map = new google.maps.Map(_this.map[0], {
            zoom: 12,
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: this.getCustomization()
        });

        map.markers = [];

        $.each(this.params.markers, function () {
            _this.add_marker($(this), map);
        });
        _this.center_map(map);

        return map;
    }

    /*
    |
    | add_marker
    |-------------
    */
    add_marker($marker, map) {
        var latlng = new google.maps.LatLng($marker.data('lat'), $marker.data('lng'));

        var icon = {
            url: $marker.data('icon'),
            scaledSize: new google.maps.Size(200, 200),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(100, 100)
        };

        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: icon
        });

        map.markers.push(marker);

        if ($marker.html()) {
            var infowindow = new google.maps.InfoWindow({
                content: '<div class="map-card">' + $marker.html() + '</div>'
            });

            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
            });
        }
    }

    /*
    |
    | center_map
    |-------------
    */
    center_map(map) {
        var bounds = new google.maps.LatLngBounds();

        $.each(map.markers, function (i, marker) {
            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

            bounds.extend(latlng);
        });

        if (map.markers.length == 1) {
            map.setCenter(bounds.getCenter());
            map.setZoom(this.params.zoom);
        } else {
            map.fitBounds(bounds);
        }

    }

    /*
    |
    | getCustomization
    |-------------------
    */
    getCustomization() {
        return [
            {
                "featureType": "all",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#202c3e"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "gamma": 0.01
                    },
                    {
                        "lightness": 20
                    },
                    {
                        "weight": "1.39"
                    },
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "weight": "0.96"
                    },
                    {
                        "saturation": "9"
                    },
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#000000"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "lightness": 30
                    },
                    {
                        "saturation": "9"
                    },
                    {
                        "color": "#29446b"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "saturation": 20
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "lightness": 20
                    },
                    {
                        "saturation": -20
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "lightness": 10
                    },
                    {
                        "saturation": -30
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#193a55"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "saturation": 25
                    },
                    {
                        "lightness": 25
                    },
                    {
                        "weight": "0.01"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": -20
                    }
                ]
            }
        ]
    }
}

export default customGoogleMap;
