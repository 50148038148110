/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {

        // Swiper
        var swiperannonce = new Swiper("#swiper-annonce", {
            spaceBetween: 0,
            slidesPerView: 3,
            centeredSlides: true,
            loop: true,
            // roundLengths: true,
            // loopAdditionalSlides: 30,

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        });

        // Filter

        jQuery('.item-presta').on('click', function () {
            if ($(this).hasClass("active")) {
                jQuery(this).removeClass("active");
                jQuery(this).find(".cms").slideUp(400);
                jQuery(this).find(".cms").css('opacity', '0');
                jQuery(this).find(".bg").slideUp(400);
            }else {
                jQuery(".item-presta").find(".cms").slideUp(400);
                jQuery(".item-presta").find(".cms").css('opacity', '0');
                jQuery(".item-presta").find(".bg").slideUp(400);

                jQuery(".item-presta").removeClass("active");
                jQuery(this).addClass("active");
                jQuery(this).find(".cms").slideDown(400);
                jQuery(this).find(".cms").css('opacity', '1');
                jQuery(this).find(".bg").slideDown(400);
            }
        });



    }
}
